import {Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {Bill, BillColumns, BillStatus} from '../../queries/useGetBills';
import i18n from '../../i18n';
import {formatNumber} from '../../utils/formatNumber';

type ColumnOptions = {
	hasReturnedBills: boolean;
	section: BillStatus[];
};

const newBills = ({hasReturnedBills}: ColumnOptions): ColumnsType<Bill> => ([
	{
		title: '#',
		dataIndex: BillColumns.billRefnum,
		key: BillColumns.billRefnum,
		sorter: true,
	},
	{
		title: i18n.t('bill.date'),
		dataIndex: BillColumns.billTimestamp,
		key: BillColumns.billTimestamp,
		sorter: true,
		render: (val) => moment(val).format('D.M.YYYY [klo] HH:mm'),
	},
	{
		title: i18n.t('bill.creator'),
		dataIndex: BillColumns.billCreatorName,
		key: BillColumns.billCreatorName,
		sorter: true,
	},
	{
		title: i18n.t('bill.sum'),
		dataIndex: BillColumns.billSum,
		key: BillColumns.billSum,
		sorter: true,
		render: (val) => formatNumber(val, '€'),
	},
	...(hasReturnedBills ? [{
		title: '',
		dataIndex: BillColumns.billStatus,
		key: BillColumns.billStatus,
		sorter: false,
		render: (val: string) => val === 'edit' ? <Tag color={'warning'}>{i18n.t('bill.view.status.edit').toUpperCase()}</Tag> : undefined,
	}] : []),
	{
		title: '',
		key: BillColumns.action,
		render: (_, record) => (
			<Link to={`/bills/${record.billId}`}>{i18n.t('bill.show-bill')}</Link>
		),
	},
]);

const archivedBills = (): ColumnsType<Bill> => ([
	{
		title: '#',
		dataIndex: BillColumns.billRefnum,
		key: BillColumns.billRefnum,
		sorter: true,
	},
	{
		title: i18n.t('bill.accounting-num'),
		dataIndex: BillColumns.billAccountingNum,
		key: BillColumns.billAccountingNum,
		sorter: true,
	},
	{
		title: i18n.t('bill.date'),
		dataIndex: BillColumns.billTimestamp,
		key: BillColumns.billTimestamp,
		sorter: true,
		render: (val) => moment(val).format('D.M.YYYY [klo] HH:mm'),
	},
	{
		title: i18n.t('bill.creator'),
		dataIndex: BillColumns.billCreatorName,
		key: BillColumns.billCreatorName,
		sorter: true,
	},
	{
		title: i18n.t('bill.sum'),
		dataIndex: BillColumns.billSum,
		key: BillColumns.billSum,
		sorter: true,
		render: (val) => formatNumber(val, '€'),
	},
	{
		title: i18n.t('bill.marked-as-paid'),
		dataIndex: BillColumns.billPaidTimestamp,
		key: BillColumns.billPaidTimestamp,
		sorter: true,
		render: (val) => val ? moment(val).format('D.M.YYYY') : '-',
	},
	{
		title: '',
		key: BillColumns.action,
		render: (_, record) => (
			<Link to={`/bills/${record.billId}`}>{i18n.t('bill.show-bill')}</Link>
		),
	},
]);

export const generateColumns = (options: ColumnOptions): ColumnsType<Bill> => {
	const {section} = options;

	if (section.includes(BillStatus.archive)) {
		return archivedBills();
	}

	return newBills(options);
};